const imagePreview = () => {
  // Creating an appendAfter function
  Element.prototype.appendAfter = function (element) {
    element.parentNode.insertBefore(this, element.nextSibling)
  }, false

  // Append the image to display in the edit/new
  const imageContainer = document.getElementById('blog-image-container')
  if (!imageContainer) return

  const imageField  = document.getElementById('blog_post_cover_image')

  const addBgImage = () => {
    imageContainer.classList.add('blog-post-cover')
    imageContainer.style = `background-image: url('${imageField.value}');`
  }

  if (imageContainer) {
    if (imageField.value != null && imageField.value != '') { addBgImage() }
    imageField.addEventListener('change', () => { addBgImage() })
  }
}

export { imagePreview }
