// This file is the entry file for the public pages
// Like the landing, the blog, …

// Landing
import { noticeBannerInit }  from '../landing/initializers/notice_banner'
import { newsFeedInit }      from '../landing/initializers/newsfeed'
import { lazyLoaderInit }    from '../landing/initializers/lazy_loader'
import { contactModaleInit } from '../landing/initializers/contact_modale'
import { mobileMenuInit }    from '../landing/initializers/mobile_menu'

// Blog
import { imgPictureReplacer } from '../landing/blog/img_picture_replacer'
import { imagePreview }       from '../landing/blog/image_preview'

////////////////////
// LANDING
////////////////////

// Initialize lazy loading for all images
lazyLoaderInit()

// Toggle the newsfeed
newsFeedInit()

// Handle the green notice banner
noticeBannerInit()

// Handle the request demo modale
contactModaleInit()

// Handle hamburger menu for mobile display
mobileMenuInit()


////////////////////
// BLOG
////////////////////

// Replace <img> tags by <picture> tags
imgPictureReplacer()

// Preview of the main image for blog post new/edit
imagePreview()
