// Change the quality of images on loading
// https://res.cloudinary.com/goeurope/image/upload/c_scale,w_500/v1551446191/Blog/yuman-tablet-kpi.webp

// Show / Hide the newsfeed
const newsFeedInit = () => {
  const toggleBtn = document.querySelector('#toggle-newsfeed')
  if (!toggleBtn) return

  const deviseContainer  = document.querySelector('#devise-container')
  const landingContainer = document.querySelector('main')
  const newsFeed         = document.querySelector('#newsfeed')

  toggleBtn.addEventListener('click', () => newsFeed.classList.toggle('hide-news'))

  if (deviseContainer)
    deviseContainer.addEventListener('click', () => {
      if (!newsFeed.classList.contains('hide-news'))
        newsFeed.classList.add('hide-news')
    })

  if (landingContainer)
    landingContainer.addEventListener('click', () => {
      if (!newsFeed.classList.contains('hide-news'))
        newsFeed.classList.add('hide-news')
    })
}

export { newsFeedInit }
