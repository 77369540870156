// Replace <img> tag by a proper <figure> tag in the blog/article show
const imgPictureReplacer = () => {
  const article = document.querySelector('.blog-post')
  if (!article) return

  const images = article.querySelectorAll('img')
  images.forEach(image => {
    const parentParagraph = image.parentElement
    const newFigure       = document.createElement('figure')
    const newFigCaption   = document.createElement('figcaption')

    newFigCaption.innerText = image.alt
    newFigure.appendChild(image)
    newFigure.appendChild(newFigCaption)

    parentParagraph.after(newFigure)
    article.removeChild(parentParagraph)
  })
}

export { imgPictureReplacer }
