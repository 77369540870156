// Discard the little green 'success !' banner on top

const noticeBannerInit = () => {
  const closeOriginWindow = document.querySelector('#close-origin')
  if (!closeOriginWindow) return

  const originWindow      = document.querySelector('.origin')
  closeOriginWindow.addEventListener('click', e => {
    e.preventDefault()
    originWindow.style.top = '-50vh'
  })
}

export { noticeBannerInit }
