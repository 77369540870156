const mobileMenuInit = () => {
  const hamburger = document.querySelector('#hamburger') // Click handler
  if (!hamburger) return

  const hamburgerController = document.querySelector('#hamburger-controller') // Image change
  const menuMobile = document.querySelector('#menu-mobile') // Display - Hide menu

  hamburger.addEventListener('click', () => {
    menuMobile.classList.toggle('hidden')
    // Swap between the ||| and the X on click
    const [newImage, oldImage] = [hamburgerController.dataset.image, hamburgerController.src]
    hamburgerController.src = newImage
    hamburgerController.dataset.image = oldImage
  })

  window.addEventListener('resize', () => {
    if (window.matchMedia('(min-width: 900px)').matches) {
      menuMobile.classList.add('hidden')
    }
  })
}

export { mobileMenuInit }
