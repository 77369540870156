// Handle the contact modale on landing page

const displayContactModale = (event) => {
  event.preventDefault()
  const overlay         = document.querySelector('.overlay')
  const modal           = document.querySelector('.modal')
  const modaleField     = document.querySelector('#modal_demo_name')
  overlay.style.display = 'flex'
  modal.style.display   = 'flex'
  if (modaleField) modaleField.focus()
}

const getItGreen = () => {
  const modalBtn = document.querySelector('.button-modal')
  const inputBox = document.querySelector('.inputs-box').querySelectorAll('input:not([type=submit]), awesomplete-selectcomplete')

  let count = 0
  for (let i = 0; i < inputBox.length; i += 1) {
    if (inputBox[i].value !== '') { count += 1 }
  }
  
  if (count === inputBox.length && grecaptcha.getResponse().length > 0) {
    modalBtn.classList.remove('background-grey')
    modalBtn.classList.add('background-green')
    modalBtn.classList.add('no-border')
    modalBtn.setAttribute('disabled', false)
    modalBtn.removeAttribute('disabled')
    modalBtn.style.cursor = 'pointer'
  } else {
    modalBtn.classList.remove('background-green')
    modalBtn.classList.remove('no-border')
    modalBtn.classList.add('background-grey')
    modalBtn.setAttribute('disabled', true)
    modalBtn.style.cursor = 'not-allowed'
  }
}

const acceptCaptcha = () => {
  getItGreen()
}

window.acceptCaptcha = acceptCaptcha

const contactModaleInit = () => {
  const bookShowcase = document.querySelectorAll('.book-showcase')
  if  (!bookShowcase) return

  if (bookShowcase.length > 0) {
    bookShowcase.forEach((link) => {
      link.addEventListener('click', displayContactModale)
    })

    // PROGRESS BAR
    const progressBar = document.querySelector('.progress-bar').querySelectorAll('div')
    const inputBox    = document.querySelector('.inputs-box').querySelectorAll('input:not([type=submit]), awesomplete-selectcomplete')
    const modalBtn    = document.querySelector('.button-modal')

    modalBtn.classList.add('background-grey')
    progressBar.forEach(div => div.classList.add('border-grey'))

    inputBox.forEach((input, id) => {
      input.classList.add('background-lightgrey');
      ['keyup', 'awesomplete-selectcomplete'].forEach(ev => {
        input.addEventListener(ev, () => {
          if (input.value === "") {
            progressBar[id].classList.add('border-grey')
            progressBar[id].classList.remove('border-green')
            input.classList.add('background-lightgrey')
            input.classList.remove('background-green')
          } else {
            progressBar[id].classList.remove('border-grey')
            progressBar[id].classList.add('border-green')
            input.classList.remove('background-lightgrey')
            input.classList.add('background-green')
          }
          
          getItGreen()
        });
      })
    })
  }
}

export { contactModaleInit }
