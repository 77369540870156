const lazyLoaderInit = () => {
  // Script to not LazyLoad already cached images
  const lazyImages = document.querySelectorAll('img[data-src]')

  // Check for Cache support — Not sure that it actually works 😅
  if (window.caches) {
    const lazyImages = Array.prototype.slice.call(document.querySelectorAll('img[data-src]'))

    Promise.all(lazyImages.map(img => {
      const src = img.getAttribute('data-src')
      // Check if response for this image is cached
      return window.caches.match(src).
        then(response => {
          if (response) {
            // The image is cached - load it
            img.setAttribute('src', src)
            img.setAttribute('alt', img.getAttribute('data-alt'))
            img.removeAttribute('data-src')
            img.removeAttribute('data-alt')
          }
        })
    })).then(initialiseLazyLoading) // Finished loads from cache, lazy load others
  } else {
    // Cache not supported - lazy load all
    initialiseLazyLoading()
  }

  function initialiseLazyLoading() {
    // Determine the images to be lazy loaded
    let observer
    const options = {
      root: null,
      rootMargin: '1000px',
      threshold: 0.01
    }

    function preloadImage(loadImage) {
      const image = loadImage
      if (!image.hasAttribute('src')) image.src = image.dataset.src
      if ((image.alt === 'Loading image…') || (image.alt === 'undefined')) image.alt = image.dataset.alt
    }

    function onIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          observer.unobserve(entry.target)
          preloadImage(entry.target)
        }
      })
    }

    if (!('IntersectionObserver' in window)) {
      Array.from(lazyImages).forEach((image) => { preloadImage(image) })
    } else {
      observer = new IntersectionObserver(onIntersection, options)
      lazyImages.forEach((image) => { observer.observe(image) })
    }
  }
}

export { lazyLoaderInit }
